import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "./spinner";
import MediaUploader from "../fileUploader";
import DocmentType from "../enums/documentType";


export default class CreditCheckSubmit extends Component {
    state = {
        isSubmitting: false,
        c: {
            totalLoanAmount: 0,
            amountToLoan: 0
        },
        application: {
            amountToLoan: 0,
            baseInterestRate: 0
        },
        toUpload: [],
        financialDocuments: [],
        creditReportUploads: [],
        creditScore: "_"
    }

    componentDidMount() {
        this.updateData();
    }

    updateData = () => {
        callApi("GET", "/api/1/credit/" + Context.applicationId() + "/" + Context.getCId() + "/" + sessionStorage.getItem("loan_creditToken"))
            .then(({ data, status }) => {
                this.setState({
                    c: data.case,
                    isCompleted: data.case.creditRatingAddedOn != null,
                    application: data.application,
                    toUpload: data.requiredDocuments,
                    financialDocuments: data.financialDocuments,
                    creditReportUploads: data.creditReportUploads
                });
            });
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.state.toUpload.length > 0) {
            alert("Je hebt nog niet alle benodigde bestanden geupload.");
            return false;
        }

        if (this.state.creditScore == "_") {
            alert("Je hebt nnog geen kredietwaardigheidsscore toegevoegd.");
            return false;
        }

        this.setState({
            isSubmitting: true
        });

        callApi("POST", "/api/1/credit/" + Context.applicationId() + "/" + Context.getCId() + "/" + sessionStorage.getItem("loan_creditToken")+"/submit", {
                creditRating: this.state.creditScore
            }).then(({data}) => {
                var goTo = this.props.goTo;

                const evt = new CustomEvent("rs_goTo", {
                    detail: {
                        goTo: goTo,
                        isInForEach: false
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                });
        
                window.dispatchEvent(evt)
            });
    }

    get = () => {
        var x = this.state.creditReportUploads.reduce((group, product) => {
            const { documentType } = product;
            group[documentType] = group[documentType] ?? [];
            group[documentType].push(product);
            return group;
        }, {});

        var arr = [];
        var keys = Object.keys(x);
        for (var i = 0; i < keys.length; i++) {
            arr.push({ key: keys[i], values: x[keys[i]] });
        }
        return arr;
    }

    fancyTypeName = (type) => {

        var x = type;
        if (type == "Proposal")
            x = "Offerte";
        if (type == "CreditReport")
            x = "Kredietwaardigheidsrapport";

        return x;
    }

    deleteFile = (key) => {
        callApi("DELETE", "/api/1/application/" + Context.applicationId() + "/documents/remove", {
            key: key
        })
            .then(({ data }) => {
                this.updateData();
            });
    }

    handleCreditRateChange = (e) =>{
        var value = e.target.value;
        this.setState({
            creditScore: value
        });

    }

    render({ }, { toUpload, c, application, creditReportUploads, financialDocuments, creditScore }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">

                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">

                    <div class="p-4 md:p-6 lg:p-8">

                        <form onSubmit={this.onSubmit}>




                            <div class="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">


                                <div class="mb-6">
                                    <h2 class="text-base font-semibold leading-7 text-brand1-500">Lening aanvraag</h2>
                                    <div>
                                        <dl class="divide-y divide-gray-100">
                                            <div class="py-6 sm:grid sm:grid-cols-3 sm:gap-4 ">
                                                <dt class="text-sm font-medium text-gray-900">Vastgesteld leenbedrag</dt>
                                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{c.totalLoanAmount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })}</dd>
                                            </div>
                                            <div class="py-6 sm:grid sm:grid-cols-3 sm:gap-4 ">
                                                <dt class="text-sm font-medium text-gray-900">Rente</dt>
                                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{c.interestRate}%</dd>
                                            </div>
                                            <div class="py-6 sm:grid sm:grid-cols-3 sm:gap-4 ">
                                                <dt class="text-sm font-medium text-gray-900">Aantal jaar</dt>
                                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{application.loanLengthInMonths / 12} jaar</dd>
                                            </div>
                                            <div class="py-6 sm:grid sm:grid-cols-3 sm:gap-4 ">
                                                <dt class="text-sm font-medium text-gray-900">Geeft aan vrijgesteld van BTW te zijn</dt>
                                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{application.isFreeOfVat ? "Ja" : "Nee"}</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>


                                <div class="mb-6">
                                    <h2 class="text-base font-semibold leading-7 text-brand1-500">Finciele documenten</h2>
                                    <div>
                                        <dl class="divide-y divide-gray-100">
                                            <div class="py-6 sm:grid sm:grid-cols-3 sm:gap-4 ">
                                                <dt class="text-sm font-medium text-gray-900">Offertes</dt>
                                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                    <ul>
                                                        {financialDocuments.filter(x => x.document.documentType == "Proposal").map(x => (
                                                            <li>
                                                                <a download href={((sessionStorage.getItem('loan_api_url') ?? "") + "/api/1/application/" + Context.applicationId() + "/file/" + x.document.key).replace("//", "/")} target="_blank">
                                                                    Download {x.document.name}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </dd>
                                            </div>
                                        </dl>
                                        <dl class="divide-y divide-gray-100">
                                            <div class="py-6 sm:grid sm:grid-cols-3 sm:gap-4 ">
                                                <dt class="text-sm font-medium text-gray-900">Jaarcijfers</dt>
                                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                    <ul>
                                                        {financialDocuments.filter(x => x.document.documentType == "Financials").map(x => (
                                                            <li>
                                                                <a download href={((sessionStorage.getItem('loan_api_url') ?? "") + "/api/1/application/" + Context.applicationId() + "/file/" + x.document.key).replace("//", "/")} target="_blank">
                                                                    Download {x.document.name}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </dd>
                                            </div>
                                        </dl>
                                        <dl class="divide-y divide-gray-100">
                                            <div class="py-6 sm:grid sm:grid-cols-3 sm:gap-4 ">
                                                <dt class="text-sm font-medium text-gray-900">Tussentijdserapportages</dt>
                                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                    <ul>
                                                        {financialDocuments.filter(x => x.document.documentType == "FinacialReportCurrentYear").map(x => (
                                                            <li>
                                                                <a download href={((sessionStorage.getItem('loan_api_url') ?? "") + "/api/1/application/" + Context.applicationId() + "/file/" + x.document.key).replace("//", "/")} target="_blank">
                                                                    Download {x.document.name}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>


                                {toUpload.length == 0 ? "" :
                                    <div class="mb-6">
                                        <h2 class="text-base font-semibold leading-7 text-brand1-500">Te uploaden</h2>

                                        <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                            {toUpload.map((x, i) => (
                                                <div class="pt-6 sm:flex">
                                                    <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                                        <label for={"email" + i} class="pt-1">
                                                            {x.message}
                                                        </label>
                                                    </dt>
                                                    <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <MediaUploader documentType={x.type} oncompleted={this.updateData} />
                                                    </dd>
                                                </div>
                                            ))}


                                        </dl>
                                    </div>
                                }

                                {creditReportUploads.length == 0 ? "" :
                                    <div class="mb-6">
                                        <h2 class="text-base font-semibold leading-7 text-brand1-500 mb-2">Wat je al hebt geupload</h2>
                                        <p>Je hebt de volgende documenten al toegevoegd:</p>

                                        {
                                            this.get().map(x => (
                                                <>
                                                    <h2 class="text-base font-semibold leading-7 text-brand2-500 mt-4">{this.fancyTypeName(x.key)} </h2>

                                                    <dl class="mt-2 space-y-4 divide-y divide-gray-100 border-gray-200 text-sm leading-4 ">

                                                        {x.values.map((x, i) => (
                                                            <div class="pt-2 sm:flex">
                                                                <dt class="font-medium text-gray-900 sm:w-1/2 sm:flex-none sm:pr-6">
                                                                    <label for={"email" + i} class="pt-1">
                                                                        <a href={((sessionStorage.getItem('loan_api_url') ?? "") + "/api/1/application/" + Context.applicationId() + "/file/" + x.key).replace("//", "/")} target="_blank">
                                                                            {x.name}
                                                                        </a>
                                                                    </label>
                                                                </dt>
                                                                <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                                    <span> </span>
                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.deleteFile(x.key) }}>verwijderen</a>
                                                                </dd>
                                                            </div>
                                                        ))}

                                                        <div class="mt-6">
                                                            <MediaUploader documentType={x.key} oncompleted={this.updateData} label={"Nog een " + this.fancyTypeName(x.key) + " toevoegen"} />
                                                        </div>
                                                    </dl>
                                                </>
                                            ))
                                        }

                                    </div>
                                }

                                <div class="mb-6">
                                    <h2 class="text-base font-semibold leading-7 text-brand1-500">Kredietwaardigheid {creditScore}</h2>
                                    <div>
                                        <label for="score" class="block text-sm font-medium leading-6 text-gray-900">Score</label>
                                        <select id="score" name="score" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-brand2-600 sm:text-sm sm:leading-6" onChange={this.handleCreditRateChange}>
                                            <option selected value="_">-</option>
                                            <option>AAA</option>
                                            <option value="AA_PLUS">AA+</option>
                                            <option>AA</option>
                                            <option value="AA_MINUS">AA-</option>
                                            <option value="A_PLUS">A+</option>
                                            <option>A</option>
                                            <option value="A_MINUS">A-</option>
                                            <option value="BBB_PLUS">BBB+</option>
                                            <option>BBB</option>                                           
                                            <option value="BBB_MINUS">BBB-</option>
                                            <option value="BB_PLUS">BB+</option>
                                            <option>BB</option> 
                                            <option value="BB_MINUS">BB-</option>
                                            <option value="B_PLUS">B+</option>
                                            <option>B</option>
                                            <option value="B_MINUS">B-</option>
                                            <option value="CCC_PLUS">CCC+</option>
                                            <option>CCC</option>
                                            <option value="CCC_MINUS">CCC-</option>
                                            <option>CC</option>
                                            <option>C</option>
                                            <option>RD</option>
                                            <option>SD</option>
                                            <option>D</option>
                                        </select>
                                    </div>
                                </div>


                            </div>


                            {((toUpload.length > 0) || (creditScore == "_")) ? "" :
                                <div class=" py-8 text-right">
                                    <button type="submit" class="inline-flex items-center rounded-full border border-transparent bg-brand2-500 px-6 py-3 text-base font-bold text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                        {this.state.isSubmitting ? <Spinner /> : "Indienen"}
                                    </button>
                                </div>
                            }

                        </form>

                    </div>

                </div>


            </section>
        )
    }
}