const Progress = ({ c }) => {
    let getCurrentStepName = () => {
        var x = getCurrentStepNumber();
        return getName(x);
    }

    let getName = (x) => {
        if (x == 4)
            return "Afgerond";
        if (x == 3)
            return "Uitbetaling";
        if (x == 2)
            return "Lopend";
        if (x == 1)
            return "Overeenkomst"
        return "Controleren";
    }

    let getCurrentStepNumber = () => {       
        if (c?.state == "Completed")
            return 4;
        if (c?.state == "PayoutRequested" || c.state == "SubmittedForPayOutApproval" || c.state == "AdvanceRequested" || c.state == "SubmittedForAdvance")
            return 3;
        if (c?.state == "ContractSigned")
            return 2;
        if (c?.state == "WaitingForContract")
            return 1;
        return 0;
    }

    let getSteps = () => {
        return [
            "Overeenkomst",
            "Lopend",
            "Uitbetaling"
        ]
    }

    let getFriendlyCurrentStepNumber = () => {
        var x = getCurrentStepNumber();
        if (x == 0)
            return 1;
        if (x == 4)
            return 3;
        return x;
    }


    return (
        <nav aria-label="Progress" class="py-4 md:py-6 lg:py-8 bg-chatbg flex" >

            <div class="w-1/3 lg:w-1/4 pl-4 md:pr-8">
                <div class="text-sm text-slate-400">Stap {getFriendlyCurrentStepNumber()} van 3:</div>
                <div class="text-sm text-slate-800 font-bold">{getCurrentStepName()}</div>
            </div>

            <div class="w-2/3 lg:w-3/4">
                <ol role="list" class="flex items-center justify-center mx-auto">
                    {getSteps().map((x, i) => {
                        var step = getCurrentStepNumber();                        
                        var current = step == i + 1;
                        var done = step > i + 1;

                        var cls = "relative pr-8 sm:pr-20 md:pr-48 lg:pr-64";
                        if (getSteps().length == i + 1)
                            cls = "relative";

                        return (
                            <li class={cls}>
                                {
                                    done ?
                                        <>
                                            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div class="h-0.5 w-full bg-brand2-600"></div>
                                            </div>
                                            <div class="relative flex h-12 w-12 items-center justify-center rounded-full bg-brand2-600 ">
                                                <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                                </svg>
                                                <span class="absolute -bottom-8 -left-0 w-48">{x}</span>

                                            </div>
                                        </>
                                        :

                                        current ?
                                            <>
                                                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                    <div class="h-0.5 w-full bg-gray-200"></div>
                                                </div>
                                                <div class="relative flex h-12 w-12 items-center justify-center rounded-full bg-white border-2 border-brand2-600 ">
                                                    <span class="h-2.5 w-2.5 rounded-full  bg-brand2-600" aria-hidden="true"></span>
                                                    <span class="absolute -bottom-8 -left-0 w-48">{x}</span>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                    <div class="h-0.5 w-full bg-gray-200"></div>
                                                </div>
                                                <div href="#" class="group relative flex h-12 w-12 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                    <span class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" aria-hidden="true"></span>
                                                    <span class="absolute -bottom-8 -left-0 w-48">{x}</span>
                                                </div>
                                            </>

                                }
                            </li>

                        )
                    })}

                </ol>
            </div>
        </nav>
    );
}

export default Progress; 