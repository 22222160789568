import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "./spinner";


export default class LoanDuration extends Component {
    state = {
        isSubmitting: false,
        canSubmit: false,
        selectedOption: null,
        isOther: false,
        options: []
    }

    componentDidMount(){   
        callApi("GET", "/api/1/application/"+Context.applicationId())
            .then(({data}) => {
                var options = [];
                var x = data.loan.allowedLengthInMonths;
                for(let i =0; i< x.length; i++ ){
                    let o = x[i];
                    options.push({
                        value: o,
                        label: (o/12) + " jaar"
                    })
                }
                this.setState({
                    options: options
                });
            });
    }

    handleListClick = (e) => {
        e.preventDefault();
        var selected = e.target.dataset["value"];
        this.setState({
            canSubmit: true,
            selectedOption: selected
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        });
        var x = e.target.elements[this.props.id];

        var goTo = this.props.goTo;

        var selectedOptions = this.state.options.filter((f) => {
            if (f.value == x.value)
                return true;
        });

        if (typeof (selectedOptions[0].goTo) !== "undefined" && selectedOptions[0].goTo.length > 0)
            goTo = selectedOptions[0].goTo

        callApi("PUT", "/api/1/application/"+Context.applicationId()+ "/loan_length", {loanLength: parseInt(selectedOptions[0].value)})
            .then(({data}) => {
                const e = new CustomEvent("rs_goTo", {
                    detail: {
                        goTo: goTo,
                        isInForEach: false
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false, 
                });
               
                window.dispatchEvent(e)
            });
    }

    render({ id, screenReaderTitle,  }, { canSubmit, selectedOption, options }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">

                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">

                    <div class="p-4 md:p-6 lg:p-8">                  

                        <form onSubmit={this.onSubmit}>
                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                                {(options.length <= 6) ? 
                                    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2">
                                        <input name={id} id={id} value={selectedOption} type="hidden" />
                                        {options.map(x => (
                                            <li data-value={x.value} class={((selectedOption == x.value) ? "bg-brand2-500 text-white " : "bg-white ") + " font-bold col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200 p-8 cursor-pointer"} onClick={this.handleListClick}>
                                                {x.label}
                                            </li>
                                        ))}
                                    </ul>
                                    :
                                    <div class="mb-6">
                                        <label for={id} class="block font-medium text-gray-700">{screenReaderTitle}</label>
                                        <select onChange={this.handleOptionChange} id={id} name={id} class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
                                            <option selected value="--">--</option>
                                            {options.map((x) => (
                                                <option value={x.value}>{x.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                }
                            </div>

                            {(canSubmit) ?
                                <div class=" py-8 text-right">
                                    <button type="submit" class="inline-flex items-center rounded-full border border-transparent bg-brand2-500 px-6 py-3 text-base font-bold text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                        {this.state.isSubmitting ? <Spinner /> : "Volgende"}
                                    </button>
                                </div>
                                : ""}
                        </form>

                    </div>

                </div>


            </section>
        )
    }
}