import React, { useCallback } from 'react'
import Dropzone from 'react-dropzone'
import { Component } from 'preact';
import { Context } from './context/context';

export default class MediaUploader extends Component {
    state = {
        isUploading: false, 
        totalFiles: 0,
        filesReady: 0
    }

    constructor(props) {
        super(props);
        if (typeof (props.oncompleted) != "function")
            this.props.oncompleted = function () { };

        var scope = this;
        this.onDrop = (acceptedFiles) => {
            scope.setState({
                totalFiles: acceptedFiles.length,
                isUploading: true
            });


            acceptedFiles.forEach((file) => {
                var formData = new FormData();
                formData.append('file', file);
                formData.append("documentType", this.props.documentType + "");
                var url = "/api/1/application/"+Context.applicationId()+"/documents"
                var fullUrl = ((sessionStorage.getItem('loan_api_url') ?? "") + url).replace("//", "/");

                var xhr = new XMLHttpRequest();
                xhr.open('POST', fullUrl);
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        var totalReady = scope.state.filesReady + 1;
                        var isComplete = totalReady == scope.state.totalFiles;

                        scope.setState({
                            totalFiles: totalReady,
                            isUploading: !isComplete
                        });
              
                        if (isComplete){
                            if(typeof(scope.props.reference) != "undefined")
                                scope.props.oncompleted(scope.props.reference, file.name)
                            else
                                scope.props.oncompleted();
                        }

                    } else {
                        console.log('Something went terribly wrong...');
                    }
                };             
                xhr.send(formData);
            })
        };
    }


    render({ label }, { isUploading }) {

        return (
            <Dropzone onDrop={this.onDrop} acceptedFiles={[".jpeg", ".jpg", ".png", ".pdf"]} >
                {({ getRootProps, getInputProps }) => (
                    <section className="container">
                        <div {...getRootProps({ className: 'Dropzone relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-8 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 mb-8' })}>
                            <input {...getInputProps()} />

                            <p>{isUploading ? "Aan het uploaden..." : (label == null || label.length == 0 ? "Klik hier om een bestand te selecteren" : label)} </p>
                        </div>
                    </section>
                )}
            </Dropzone>
        );
    }
}
