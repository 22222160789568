import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "./spinner";


export default class ListSigners extends Component {
    state = {
        isSubmitting: false,
        companySigners: [],
        personSigners: [],
        companyApplicant: null
    }

    componentDidMount() {
        callApi("GET", "/api/1/application/" + Context.applicationId())
            .then(({ data }) => {            
                this.setState({
                    companySigners: data.companySigners,
                    personSigners: data.personSigners,
                    companyApplicant: data.companyApplicant
                });
            });
    }


    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        });

        var goTo = this.props.goTo;

        var formData = new FormData(e.target);
        const persons = [];
        const ids = formData.getAll("id");
        const emails = formData.getAll("email");
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            const email = emails[i];
            persons.push({
                id,
                email,
            })
        }

        var actions = [];
        for(let i = 0; i < persons.length; i++){
            var p = persons[i];
            var personId = p.id;
            var email = p.email;
            actions.push( callApi("PUT", "/api/1/application/" + Context.applicationId() + "/person/" + personId + "/email", { email: email }));
        }
        
        Promise.all(actions).then(() => {
            const e = new CustomEvent("rs_goTo", {
                detail: {
                    goTo: goTo,
                    isInForEach: false
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(e)
        });    
    }

    getCompanyName = (companyId) =>{
        var c = this.state.companySigners.filter(x => x.id == companyId);
        if(c.length > 0)
            return c[0].name;

        if(this.state.companyApplicant.id == companyId)
            return this.state.companyApplicant.name;

        return companyId;
    }

    render({ }, { companySigners, personSigners }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">

                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">

                    <div class="p-4 md:p-6 lg:p-8">

                        <form onSubmit={this.onSubmit}>

                            <div class="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                                <div>
                                    <h2 class="text-base font-semibold leading-7 text-brand1-500">Ondertekenaars</h2>
                                  
                                    <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                        {personSigners.map((x, i) => (
                                            <div class="pt-6 sm:flex">
                                                <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                                    <label for={"email"+i} class="pt-1">
                                                        {x.firstName} {x.middleName} {x.lastName}
                                                        {x.companyId == null ? "" : <span class="text-gray-500"> ({this.getCompanyName(x.companyId)})</span>}
                                                    </label>
                                                </dt>
                                                <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">                                                  
                                                    <input type="hidden" name={"id"} value={x.id} />
                                                    <input name={"email"} type="email" required id={"email"+i} placeholder="email@adres.nl" value={x.email} class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-brand2-500 block w-full p-2.5 "/>
                                                </dd>
                                            </div>
                                        ))}


                                    </dl>
                                </div>

                            </div>



                            <div class=" py-8 text-right">
                                <button type="submit" class="inline-flex items-center rounded-full border border-transparent bg-brand2-500 px-6 py-3 text-base font-bold text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                    {this.state.isSubmitting ? <Spinner /> : "Volgende"}
                                </button>
                            </div>

                        </form>

                    </div>

                </div>


            </section>
        )
    }
}