import { Component } from "preact";

export default class Text extends Component {
    state = {
        showText: false,
        canShowNextButton: true
    }

    toggleDisplayMode = (e) => {
        e.preventDefault();
        this.setState({
            showText: !this.state.showText,
            canShowNextButton: true
        });
    }

    goNext = (e) => {
        e.preventDefault();
        const evt = new CustomEvent("rs_goTo", {
            detail: {
                goTo: this.props.goTo
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        });

        window.dispatchEvent(evt)
    }

    render({ header, text, file }, { showText, canShowNextButton, reachedPausedMoment }) {
        return (<>
            <div class="relative bg-white rounded-lg shadow-lg text-slate-800  mb-8">
                <div class="p-4 md:p-6 lg:p-8">
                    <h2 class="truncate text-2xl font-bold text-gray-900 pb-8">{header}</h2>
                    <div class="prose ">
                        {text.map((x) => (
                            <p class="pb-4 text-gray-900" dangerouslySetInnerHTML={{ __html: x }}></p>
                        ))}                       
                    </div>
               
                    <div class="py-8 text-right">                     
                        <a onclick={this.goNext} href="#" class="inline-flex items-center rounded-full border border-transparent bg-brand2-500 px-6 py-3 text-base font-bold text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Volgende</a>                      
                    </div>
                </div>
            </div>
        </>);
    }
} 