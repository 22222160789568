
import { Component } from "preact";
import Spinner from "./spinner";
import { Context } from "../context/context";
import { callApi } from "../callApi";


export default class AmountOfSubsidyReceived extends Component {
    state = {
        isSubmitting: false,
        error: null
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.isSubmitting)
            return;

        this.setState({
            isSubmitting: true
        });

        var goTo = this.props.goTo;

        callApi("PUT", "/api/1/application/" + Context.applicationId() + "/energyfunddrenthe/amount_of_subsidy_received", {
            value: e.target.elements["value"].value
        }).then(({ status, data }) => {       
            if (status == 400) {
                this.setState({
                    error: data,
                    isSubmitting: false
                });
            }
            if (status == 200) {
                const e = new CustomEvent("rs_goTo", {
                    detail: {
                        goTo: goTo,
                        isInForEach: false
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                });

                window.dispatchEvent(e)
            }
        });
    }

    render({ }, { error }) { 
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <form onSubmit={this.handleSubmit}>
                        <div class="p-4 md:p-6 lg:p-8">
                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                                <div class="mb-6">
                                    <div class="relative mt-2 rounded-md shadow-sm">
                                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </div>
                                        <input type="numer" required min="1" name="value" id="value" class="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand1-600 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                <button type="submit" class="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand2-500 text-base font-bold text-white hover:bg-brand2-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand2-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                    {this.state.isSubmitting ? <Spinner /> : "Verder"}
                                </button>
                            </div>

                            {error == null ? "" :
                                <div class="rounded-md bg-red-50 p-4 mt-4">
                                    <div class="flex">
                                        <div class="flex-shrink-0">
                                            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        <div class="ml-3">
                                            <h3 class="text-sm font-medium text-red-800">Oh nee! Er lijkt iets mis te gaan.</h3>
                                            <div class="mt-2 text-sm text-red-700">
                                                <ul role="list" class="list-disc space-y-1 pl-5">
                                                    <li>{error}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </form>

                </div>

            </section>
        )
    }
}