import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";

export default class AbstractQuestion extends Component {
    submit(value, goto, isInForEach ) {
        callApi("POST", "/api/RecordAnswer",{
            "MeasurementId": Context.measurementId(),
            "ParticipatorId": Context.applicationId(),            
            "QuestionCode": this.props.id,
            "Value": value+"",
        }).then(() => {
            const e = new CustomEvent("rs_goTo", {
                detail: {
                    goTo: goto,
                    isInForEach: typeof(isInForEach) == "undefined" ? false : isInForEach
                },
                bubbles: true,
                cancelable: true,
                composed: false, 
            });
           
            window.dispatchEvent(e)
        });
    }
}