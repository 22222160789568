const ChatTyping = (props) => {

    return (
        <section class="block relative pl-10 mb-8 md:mb-10 lg:mb-12">          
            <img class="absolute bottom-0 left-0 block h-8 w-8 border border-slate-400 rounded-full opacity-50"
                src="/assets/images/cora.jpg?v=1"
                alt="" />
    
            <div class="relative h-8 flex items-center">
                <div class="w-2 h-2 bg-brand1-300 animate-bounce rounded-full ml-1"></div>
                <div class="w-2 h-2 bg-brand1-400 animation-delay-25 animate-bounce rounded-full ml-1"></div>
                <div class="w-2 h-2 bg-brand1-500 animation-delay-50 animate-bounce rounded-full ml-1"></div>
            </div>
        </section>
    );   
}

export default ChatTyping; 