
import { Component } from "preact";
import Spinner from "./spinner";
import { Context } from "../context/context";
import { callApi } from "../callApi";


export default class Iban extends Component {
    state = {
        isSubmitting: false,
        error: null
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.isSubmitting)
            return;

        this.setState({
            isSubmitting: true
        });

        var goTo = this.props.goTo;
        var iban = e.target.elements["iban"].value.replaceAll(" ", "").toUpperCase();

        callApi("PUT", "/api/1/application/" + Context.applicationId() + "/iban", {
            iban: iban
        }).then(({ status, data }) => {       
            if (status == 400) {
                this.setState({ 
                    error: data,
                    isSubmitting: false
                });
            }
            if (status == 200) {
                const e = new CustomEvent("rs_goTo", {
                    detail: {
                        goTo: goTo,
                        isInForEach: false
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                });

                window.dispatchEvent(e)
            }
        });
    }

    render({ }, { error }) { 
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <form onSubmit={this.handleSubmit}>
                        <div class="p-4 md:p-6 lg:p-8">
                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                                <div class="mb-6">

                                    <div class="relative mt-2 rounded-md shadow-sm">
                                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                            </svg>
                                        </div>
                                        <input type="text" name="iban" id="iban" class="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand1-600 sm:text-sm sm:leading-6" />
                                    </div>

                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                <button type="submit" class="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand2-500 text-base font-bold text-white hover:bg-brand2-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand2-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                    {this.state.isSubmitting ? <Spinner /> : "Verder"}
                                </button>
                            </div>

                            {error == null ? "" :
                                <div class="rounded-md bg-red-50 p-4 mt-4">
                                    <div class="flex">
                                        <div class="flex-shrink-0">
                                            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        <div class="ml-3">
                                            <h3 class="text-sm font-medium text-red-800">Oh nee! Er lijkt iets mis te gaan.</h3>
                                            <div class="mt-2 text-sm text-red-700">
                                                <ul role="list" class="list-disc space-y-1 pl-5">
                                                    <li>{error}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </form>

                </div>

            </section>
        )
    }
}