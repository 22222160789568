import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "./spinner";


export default class ListSignersFree extends Component {
    state = {
        isSubmitting: false,
        companySigners: [],
        personSigners: [],
        companyApplicant: null,
        showAddPersonForm: false,
    }

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        callApi("GET", "/api/1/application/" + Context.applicationId())
            .then(({ data }) => {
                this.setState({
                    companySigners: data.companySigners,
                    personSigners: data.personSigners,
                    companyApplicant: data.companyApplicant
                });
            });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        });

        var goTo = this.props.goTo;

        var formData = new FormData(e.target);
        const persons = [];
        const ids = formData.getAll("id");
        const emails = formData.getAll("email");
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            const email = emails[i];
            persons.push({
                id,
                email,
            })
        }

        var actions = [];
        for (let i = 0; i < persons.length; i++) {
            var p = persons[i];
            var personId = p.id;
            var email = p.email;
            actions.push(callApi("PUT", "/api/1/application/" + Context.applicationId() + "/person/" + personId + "/email", { email: email }));
        }

        Promise.all(actions).then(() => {
            const e = new CustomEvent("rs_goTo", {
                detail: {
                    goTo: goTo,
                    isInForEach: false
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(e)
        });
    }

    getCompanyName = (companyId) => {
        var c = this.state.companySigners.filter(x => x.id == companyId);
        if (c.length > 0)
            return c[0].name;

        if (this.state.companyApplicant.id == companyId)
            return this.state.companyApplicant.name;

        return companyId;
    }

    addPerson = (e) => {
        e.preventDefault();
        this.setState({
            showAddPersonForm: true
        });
    }

    handleBgClick = (e) =>{
        if(e.target.tagName == "DIV" && e.target.classList.contains("min-h-full"))
            this.closeAddForm();
    }

    closeAddForm = () => {
        this.setState({
            showAddPersonForm: false
        });
    }

    handleAddPersonSubmit = (e) =>{
        e.preventDefault();
        var data = new FormData(e.target); 
        const formDataObj = Object.fromEntries(data.entries());

        callApi("POST", "/api/1/application/" + Context.applicationId() + "/signer", formDataObj).then(({ status, data }) => {
            if (status == 200) {
                this.refreshData();
                this.closeAddForm();
            } 
        });
    }


    deletePerson = (e) => {
        e.preventDefault();  
        var areYouSure = confirm("Weet je het zeker?")
        if (!areYouSure)
            return;

        var t = e.target;
        var personId =  t.dataset["id"];
        
        while(personId == null){
            t = t.parentNode;
            personId = t.dataset["id"];
        }

        callApi("DELETE", "/api/1/application/" + Context.applicationId() + "/signer/"+personId).then(({ status, data }) => {
            if (status == 200) {
                this.refreshData();              
            } 
        });
    }

    
    render({ }, { personSigners, showAddPersonForm }) {
        return (
            <>
                <section class="block relative mb-8 md:mb-10 lg:mb-12">
                    <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                        <div class="p-4 md:p-6 lg:p-8">

                            <form onSubmit={this.onSubmit}>
                                <div class="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                                    <div>
                                        <h2 class="text-base font-semibold leading-7 text-brand1-500">Ondertekenaars</h2>

                                        <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                            {personSigners.map((x, i) => (
                                                <div class="pt-6 sm:flex">
                                                    <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                                        <label for={"email" + i} class="pt-1">
                                                            {x.firstName} {x.middleName} {x.lastName}
                                                            {x.companyId == null ? "" : <span class="text-gray-500"> ({this.getCompanyName(x.companyId)})</span>}
                                                        </label>
                                                    </dt>
                                                    <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <input type="hidden" name={"id"} value={x.id} />
                                                        <input name={"email"} type="email" required id={"email" + i} placeholder="email@adres.nl" value={x.email} class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-brand2-500 block w-full p-2.5 " />
                                                        <a href="#" data-id={x.id} onClick={(e) => this.deletePerson(e)} class="inline-flex items-center rounded-full border border-transparent bg-slate-200 px-2 py-1 text-base font-bold hover:text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-center h-10 w-10">
                                                            <svg data-id={x.id} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-full">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                            </svg>
                                                        </a>
                                                    </dd>
                                                </div>
                                            ))}

                                        </dl>

                                        <a href="#" class="mt-3 inline-flex items-center rounded-full border border-transparent bg-brand2-500 px-6 py-3 text-base font-bold text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" onClick={(e) => this.addPerson(e)}>
                                            Persoon toevoegen
                                        </a>
                                    </div>

                                </div>


                                {personSigners.length > 0 ?
                                <div class=" py-8 text-right">
                                    <button type="submit" class="inline-flex items-center rounded-full border border-transparent bg-brand2-500 px-6 py-3 text-base font-bold text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                        {this.state.isSubmitting ? <Spinner /> : "Volgende"}
                                    </button>
                                </div> : ""}

                            </form>

                        </div>

                    </div>


                </section>
                {showAddPersonForm == true ? <>
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>
                    <div class="fixed inset-0 z-10 w-screen overflow-y-auto" onClick={(e) => this.handleBgClick(e)}>
                        <div class="flex min-h-full items-end justify-center  p-4 text-center sm:items-center sm:p-0">

                            <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                                <form onSubmit={(e) => this.handleAddPersonSubmit(e)}>
                                    <div>
                                        <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-5 sm:gap-x-4">
                                            <div class="sm:col-span-2">
                                                <label for="firstName" class="block text-sm font-medium text-gray-700">Voornaam *</label>
                                                <div class="mt-1">
                                                    <input required type="text" id="firstName" name="firstName" autocomplete="firstName" class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-brand2-500 block w-full p-2.5 " />
                                                </div>
                                            </div>

                                            <div>
                                                <label for="middleName" class="block text-sm font-medium text-gray-700">Tuss.</label>
                                                <div class="mt-1">
                                                    <input type="text" id="middleName" name="middleName" autocomplete="middleName" class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-brand2-500 block w-full p-2.5 " />
                                                </div>
                                            </div>

                                            <div class="sm:col-span-2">
                                                <label for="lastName" class="block text-sm font-medium text-gray-700">Achternaam *</label>
                                                <div class="mt-1">
                                                    <input required type="text" id="lastName" name="lastName" autocomplete="lastName" class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-brand2-500 block w-full p-2.5 " />
                                                </div>
                                            </div>

                                            <div class="sm:col-span-5">
                                                <label for="email" class="block text-sm font-medium text-gray-700">E-mail *</label>
                                                <div class="mt-1">
                                                    <input required type="email" name="email" id="email" class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-brand2-500 block w-full p-2.5 " />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="mt-5 sm:mt-6">
                                        <button type="submit" class="inline-flex text-center items-center w-full rounded-full border border-transparent bg-brand2-500 px-6 py-3 text-base font-bold text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Persoon toevoegen</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </> : ""}
            </>
        )
    }
}