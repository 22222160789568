
import AbstractQuestion from "./abstractQuestion";
import Spinner from "./spinner";


export default class TextField extends AbstractQuestion {
    state = {
        isSubmitting: false
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.isSubmitting)
            return;
 
        this.setState({ 
            isSubmitting: true
        });

        super.submit(e.target.elements[this.props.id].value, this.props.goTo, this.props.isInForEach);
    }

    render({ id,  max }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <form onSubmit={this.handleSubmit}>
                        <div class="p-4 md:p-6 lg:p-8">
                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                                <div class="mb-6">                   
                                    <textarea required name={id} id={id} class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-brand2-500 block w-full p-2.5 " />
                                </div>
                            </div>
                            <div class=" py-8 text-right">
                                <button type="submit" class="inline-flex items-center rounded-full border border-transparent bg-brand2-500 px-6 py-3 text-base font-bold text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                    {this.state.isSubmitting ? <Spinner /> : "Verder"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>        
            </section>
        )
    }
}