import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "./spinner";


export default class SubmitAdvance extends Component {
    state = {
        isSubmitting: false,
        application: null,
        error: null
    }

    componentDidMount() {
        callApi("GET", "/api/1/application/" + Context.applicationId()+"/"+Context.getCId() )
            .then(({ data }) => {
                this.setState({
                    application: data.application,
                    c: data.case
                });
            });
    }

    handleListClick = (e) => {
        e.preventDefault();
        var selected = e.target.dataset["value"];
        this.setState({
            canSubmit: true,
            selectedOption: selected
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        });

        var goTo = this.props.goTo;

        callApi("POST", "/api/1/case/" + Context.getCId() + "/submit_advance").then(({ status, data }) => {
            if (status == 400) {
                this.setState({
                    error: [data],
                    isSubmitting: false
                });
            }
            if (status == 200) {
                const evt = new CustomEvent("rs_goTo", {
                    detail: {
                        goTo: goTo,
                        isInForEach: false
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                });

                window.dispatchEvent(evt);
            }
        });

    }

    render({ }, { error, application, c }) {
        if (application == null)
            return (<></>);

        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">

                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">

                    <div class="p-4 md:p-6 lg:p-8">

                        <form onSubmit={this.onSubmit}>
                            <div class="relative mb-4 md:mb-6 lg:mb-8">

                                <div class="px-4 py-6 sm:px-6">
                                    <h3 class="text-base font-semibold leading-7 text-gray-900">Aanvraag voorschot {application.loan.loanName}</h3>
                                    <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">door {application.contactPerson.firstName} {application.contactPerson.middleName} {application.contactPerson.lastName}.</p>
                                </div>
                                <div class="border-t border-gray-100">
                                    <dl class="divide-y divide-gray-100">
                                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">Aanvrager</dt>
                                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{application.companyApplicant.name}</dd>
                                        </div>    
                                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">Vastgestelde leenbedrag</dt>
                                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{(c.totalLoanAmount).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })}</dd>
                                        </div>                                 
                                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">Aangevraagd voorschot (75%)</dt>
                                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{(c.totalLoanAmount * 0.75).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })}</dd>
                                        </div>
                                    </dl>
                                </div>

                            </div>


                            <div class=" py-8 text-right">
                                <button type="submit" class="inline-flex items-center rounded-full border border-transparent bg-brand2-500 px-6 py-3 text-base font-bold text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                    {this.state.isSubmitting ? <Spinner /> : "Voorschot aanvragen"}
                                </button>
                            </div>



                            {error == null ? "" :
                                <div class="rounded-md bg-red-50 p-4 mt-4">
                                    <div class="flex">
                                        <div class="flex-shrink-0">
                                            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        <div class="ml-3">
                                            <h3 class="text-sm font-medium text-red-800">Oh nee! Er lijkt iets mis te gaan.</h3>
                                            <div class="mt-2 text-sm text-red-700">
                                                <ul role="list" class="list-disc space-y-1 pl-5">
                                                    {error.map(x => (
                                                        <li>{x}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </form>

                    </div>

                </div>


            </section>
        )
    }
}