import { Component, createRef } from "preact";
import { callApi, loadStatic } from "../callApi";
import Question from "../questions/question";
import Video from "../video/video"
import ChatMessage from "./message";
import Router from "../router/router";
import Text from "../video/text";
import { Context } from "../context/context";
import Display from "../datadisplay/display";


export default class ChatConversation extends Component {
    state = {
        messages: [],
        history: [],
        progress: 0,
        script: 0,
        totalscriptLength: 0,
        chapterName: ""
    };

    ref = createRef();
    bar = createRef();

    compoments = {
        "ChatMessage": ChatMessage,
        "Question": Question,
        "Video" : Video,
        "Router": Router,
        "Text" : Text,      
        "Display" : Display
    }

    componentDidMount() {
        var list = this.props.list;              
        this.loadQuestionList(list);
        var scope = this;

        window.addEventListener("rs_goTo", function(e){       
            if(e.detail.isInForEach)
                return;                  
            scope.goToFlowItem(e.detail.goTo);
        });
    }
  
    componentDidUpdate() {
        this.scrollToBottom()
    }

    scrollToBottom = () => {
        this.el.scrollIntoView({ behavior: 'smooth' });
    }

    loadQuestionList = (questionList) => {
        loadStatic("get", "/assets/flows/" + questionList + ".json?v=4")
            .then(data => {
                this.setState({
                    progress: 0,
                    script: 0,
                    totalscriptLength: data.conversation.length
                }, () => {   
                    this.script = data.conversation;
                    var fId = sessionStorage.getItem("loan_questionlist");             
                    if(fId != null && fId == questionList){
                        var h = sessionStorage.getItem("loan_questionlistHistory")?.split(",") ?? [];
                        this.setState({
                            history: h
                        }, () =>{
                            if(h.length > 1)                           
                                this.goToFlowItem(h[h.length - 1])                            
                            else                             
                                this.showNext();
                        });     
                    }
                    else{
                        sessionStorage.setItem("loan_questionlist", questionList);
                        sessionStorage.setItem("loan_questionlistHistory", "");
                        this.showNext();                                    
                    }
                })
            });
    }

    goToFlowItem = (goTo) => {
        this.ref.current.classList.add("animate-disappear");

        if(goTo.length == 0){
            this.setState({
                messages: [],
                progress: 0,
                script: 0,
                history: []
            }, () => {
                this.ref.current.classList.remove("animate-disappear");
                this.bar.current.style.width = "0%";  
                this.storeProgress(0);               
                this.showNext(); 
                this.storeHistory();
            });
        }

        else{        
            for(var i = 0; i < this.script.length; i++){
                var x = this.script[i];
                if(x.id == goTo){
                    var h = this.state.history;
                    if(h.indexOf(goTo) == -1)
                        h.push(goTo);

                    this.setState({
                        messages: [],
                        progress: 0,
                        script: i,
                        history: h
                    }, () => {
                        this.ref.current.classList.remove("animate-disappear");
                        var percentage = ((this.state.script / this.script.length) * 100) ;
                        this.bar.current.style.width = percentage + "%";         
                        this.storeProgress(percentage);
                        this.showNext(); 
                        this.storeHistory();
                    });
                }
            };
        }
    }
 
    storeHistory = () =>{
        sessionStorage.setItem("loan_questionlistHistory", this.state.history.join(","));
        callApi("PUT", "/api/1/application/"+Context.applicationId()+"/flow/"+sessionStorage.getItem("loan_questionlist")+"/history", {           
            "history": this.state.history.join("|")
          });
    }

    storeProgress = (percentage) =>{
     
    }

    onAfterItemRender = () => {
        this.forceUpdate();
        this.setState({ progress: this.state.progress + 1 }, () => {
            setTimeout(this.showNext, 1500);
            this.bar.current.style.width = (((this.state.script / this.script.length) + ((this.state.progress / this.script[this.state.script].content.length) / this.script.length)) * 100) + "%";
        });
    }

    showNext = () => {
        var msgs = this.state.messages.slice(); 
        var script = this.script[this.state.script];
        msgs.push(script.content[this.state.progress]);

        var chapterName = script.chapterName;
        if(script.chapter > 0)
            chapterName = script.chapter + ". " + script.chapterName

        this.setState({ 
            messages: msgs,
            chapterName: chapterName
        });

        if ((this.state.progress + 1) == this.script[this.state.script].content.length)
            return;
    }

    goBack = (e) =>{
        e.preventDefault(); 
        var h = this.state.history;                
        h.pop();
      
        if(h.length == 0){
            this.setState({
                history: [],
                progress: 0,
                messages: [],
                script: 0
            }, () => {              
                this.showNext();
                this.storeHistory();
            });            
        }

        else{         
            while(h[h.length - 1].startsWith("check") || h[h.length - 1].startsWith("Check")){
                h.pop();
            }
            this.setState({
                history: h
            }, () => {    
                this.goToFlowItem(h[h.length - 1]);
                this.storeHistory();
            });          
        }
    }

    render({ showBarBottom = false }, { messages }) {
        return ( 
            <div class="w-full">
                {showBarBottom ? "" :
                <div class="h-2 md:h-3 lg:h-4 bg-slate-200 sticky top-0 left-0 right-0  z-10">
                    <div ref={this.bar} style="width: 0%;" class="h-full transition-all bg-brand1-500"></div>
                </div>
                }

                <div class="pt-8 lg:pt-16" >
                    <div ref={this.ref} class="z-0 relative max-w-4xl mx-auto">
                        {this.state.script > 0 && (this.state.totalscriptLength - this.state.script > 1) ? (
                            <div class="mb-8 md:mb-10 lg:mb-12 text-right">
                                <a href="#" class="inline-flex items-center rounded-full border border-transparent bg-slate-200 px-6 py-3 text-base font-bold hover:text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" onClick={this.goBack}>
                                    <svg clip-rule="evenodd" class="w-4 h-4 mr-1" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path class="fill-current" d="m18.787 9.473s-4.505-4.502-6.259-6.255c-.147-.146-.339-.22-.53-.22-.192 0-.384.074-.531.22-1.753 1.753-6.256 6.252-6.256 6.252-.147.147-.219.339-.217.532.001.19.075.38.221.525.292.293.766.295 1.056.004l4.977-4.976v14.692c0 .414.336.75.75.75.413 0 .75-.336.75-.75v-14.692l4.978 4.978c.289.29.762.287 1.055-.006.145-.145.219-.335.221-.525.002-.192-.07-.384-.215-.529z" fill-rule="nonzero"></path>
                                    </svg>
                                    terug
                                </a>
                            </div>
                        ) : ""}

                        {messages.map(x => {
                            if(typeof(x) !== "undefined"){
                                var RenderItem = this.compoments[x.display];
                                return (
                                    <RenderItem {...x.props} afterRender={this.onAfterItemRender} />
                                )
                            }
                        })}
                    </div>
                    <div ref={el => { this.el = el }}></div>
                </div>

                {!showBarBottom ? "" :
                <div class="h-2 md:h-3 lg:h-4 bg-slate-200 sticky top-0 left-0 right-0  z-10 mt-16">
                    <div ref={this.bar} style="width: 0%;" class="h-full transition-all bg-brand1-500"></div>
                </div>
                }
            </div>
        );
    }
}
