import { Component } from 'preact';
import { Router } from 'preact-router';

import Home from '../routes/home';
import Application from '../routes/application';
import DeoSkin from './deoskin';
import Thanks from '../routes/thanks';
import TracAndTrace from '../routes/trackAndTrace';
import TracAndTraceExtraFiles from '../routes/trackAndTrace/extra_files';
import CreditCheck from '../routes/creditcheck/creditcheck';

export default class App extends Component {
	componentWillMount() {
		sessionStorage.setItem('rs_asset_url', '/');		
		if (window.location.hostname == "localhost" && window.location.port == 8080) 
			sessionStorage.setItem('loan_api_url', 'https://localhost:7293');		
		else
			sessionStorage.setItem('loan_api_url', '/');			
	}

	render({ }, { }) { 
		return (
				<>	
				<DeoSkin>
					<Router>
						<Home path="/" />	
						<Thanks path="/bedankt" />	
						<Application path="/aanvragen/energielening-drenthe" list="EnergyLoanDrenthe" loanId="78bb099f-3b3c-4e2c-a35d-730c48c3ff62" />		
						<Application path="/aanvragen/energielening-plus" list="EnergyLoanPlus" loanId="681010b9-d6db-4d90-b8ef-38be3f469a3e" />		
						<Application path="/aanvragen/maatschappelijke-eergielening" list="SocialEnergyLoan" loanId="96afdf5f-4da2-4e94-b7c1-22429167bd63" />		
						<Application path="/aanvragen/energielening-collectieven" list="CollectiveEnergyLoan" loanId="2c85bc64-719a-4848-a811-e4855fc2d516" />				
						<TracAndTrace path="/mijn-aanvraag/:applicationId/:caseId" />						
						<TracAndTraceExtraFiles path="/mijn-aanvraag/:applicationId/:caseId/extra_bestanden" />
						<CreditCheck path="/creditcheck/:applicationId/:caseId/:token" />
					</Router>
				</DeoSkin>		
			</>  
		)
	}
}  

 