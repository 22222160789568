import { Component } from 'preact';
import { callApi } from '../../components/callApi';
import Spinner from '../../components/questions/spinner';
import ChatConversation from '../../components/page/conversation';
import Progress from '../../components/progress';

export default class TracAndTraceExtraFiles extends Component {
    state = {
        isLoaded: false,
        isValid: false,
        c: {},
        application: {},
        extraDocuments: []
    }

    componentDidMount() {
        callApi("GET", "/api/1/application/" + this.props.applicationId + "/" + this.props.caseId)
            .then(({ data, status }) => {
                this.setState({
                    isLoaded: true,
                    isValid: status == 200,
                    c: data.case,
                    application: data.application
                });

                if (status == 200) {
                    sessionStorage.setItem("loan_applicationId", this.props.applicationId);
                    sessionStorage.setItem("loan_aid", this.props.applicationId);
                    sessionStorage.setItem("loan_cid", this.props.caseId);
                    sessionStorage.setItem("loan_caseId", this.props.caseId);
                } else {
                    sessionStorage.removeItem("loan_applicationId", this.props.applicationId)
                    sessionStorage.removeItem("loan_aid");
                    sessionStorage.removeItem("loan_cid");
                    sessionStorage.removeItem("loan_caseId");
                }
            });

        callApi("GET", "/api/1/application/" + this.props.applicationId + "/extra_documents")
            .then(({ data }) => {
                this.setState({
                    extraDocuments: data.toUpload.filter(x => x.isSubmitted == false)
                });
            })
    }

    getCorrectFlow = (state) => {       
       /* if (state == "Completed")
            return "Completed";
        if (state == "Refused")
            return "Refused";
*/
        return "ExtraBestanden";
    }

    render({ }, { isLoaded, isValid, c, extraDocuments }) {

        if (!isLoaded)
            return (
                <div class="text-center mx-auto">
                    <div class="mx-auto h-6 w-6 mb-4 ">
                        <Spinner extraClass="text-brand1-600" />
                    </div>
                    <p class="text-sm">We pakken je gegevens erbij...</p>
                </div>
            )

        if (!isValid)
            return (
                <div class="text-center mx-auto">
                    <p class="text-sm">Deze link is (niet) meer actief</p>
                </div>
            )


        return (
            <>
                <div class="max-w-4xl mx-auto">
                    <Progress c={c} />
                </div>
                <ChatConversation list={this.getCorrectFlow(c?.state)} showBarBottom={true} />
            </>
        )
    }
}