import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "../questions/spinner";

export default class CaseStatus extends Component {
    state = {
        isLoading: true,
        items: {}
    }

    componentDidMount() {
        callApi("GET", "/api/1/application/" + Context.getAId() + "/" + Context.getCId() + "/")
            .then(({data}) => {
                var items = [         
                    {
                        title: "Document controle",
                        toDoLine: "Ik kijk of alle documenten goed geupload zijn en voldoen aan de regels.",
                        finishedLine: "Ik heb alle document (voolopig) goedgekeurd",
                        isFinished: data.case.requiredFilesPresentOn != null
                    },
                    {
                        title: "Te lenen bedrag gecontroleerd",
                        toDoLine: "Ik kijk of de offerte overeenkomt met het aangegeven bedrag.",
                        finishedLine: "Het te lenen bedrag heb ik voorlopig vastgesteld op: " + data.case.totalLoanAmount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' }),
                        isFinished: data.case.isTotalLoanAmountChecked == true
                    },
                    {
                        title: "Digitaal ondertekenen klaarzetten",
                        toDoLine: "Ik ga de juiste contactgegevens voor digitaal ondertekenen opvoeren.",
                        finishedLine: "Ik heb de de contactgegevens voor digitaal ondertekenen opgevoerd.",
                        isFinished: data.case.digitalSigningSavedOn != null
                    },
                    {
                        title: "Aangeboden aan een collega ter controle",
                        toDoLine: "Een collega van mij gaat dan dit dossier controleren",
                        finishedLine: "Een collega is dit dossier nu aan het controleren",
                        isFinished: data.case.state == "SubmittedForContract"
                    },
                    {
                        title: "Helemaal goedgekeurd!",
                        toDoLine: "Als deze stap is gehaald ontvang je de leenovereenkomst ter ondertekening.",
                        finishedLine: "Als deze stap is gehaald ontvang je de leenovereenkomst ter ondertekening.",
                        isFinished: false
                    }
                ];
              
                if(data.application._type == "EnergyLoanPlusApplicationDto"){                  
                    items.push(
                        {
                            title: "Kredietwaardigheidscontrole voorbereiden",
                            toDoLine: "Ik controleer de volledigheid van de aangelverde finaciëlebestanden bestanden.",
                            finishedLine: "Ik heb de volledigheid van de aangelverde finaciëlebestanden gecontroleerd.",
                            isFinished: data.case.isReadyForCreditRating == true
                        }
                    );

                    items.push( 
                        {
                            title: "Kredietwaardigheidscontrole",
                            toDoLine: "Aan de hand van de finaciëlebestanden bestanden doen we een kredietcontrole.",
                            finishedLine: "De kredietcontrole is afgerond.",
                            isFinished: data.case.isCreditRatingAdded == true
                        }
                    );

                    if(data.application.isLoanCollateralPossible)
                        items.push(
                            {
                                title: "Zekerheden",
                                toDoLine: "Ik stel de zekerheden vast en stel dan een overeenkomst hiervoor op.",
                                finishedLine: "De overeenkomst is verwerkt.",
                                isFinished: data.case.isCollateralApproved == true    
                            }
                        );
                }

                if(data.application._type == "CollectiveEnergyLoanApplicationDto"){     
                    var amount = data.case.approvedLoanAmount > 5000 ? data.case.approvedLoanAmount : data.application.amountToLoan;
                    if(amount > 50000){
                        items.push(
                            {
                                title: "Kredietwaardigheidscontrole voorbereiden",
                                toDoLine: "Ik controleer de volledigheid van de aangelverde finaciëlebestanden bestanden.",
                                finishedLine: "Ik heb de volledigheid van de aangelverde finaciëlebestanden gecontroleerd.",
                                isFinished: data.case.isReadyForCreditRating == true
                            }
                        );
    
                        items.push( 
                            {
                                title: "Kredietwaardigheidscontrole",
                                toDoLine: "Aan de hand van de finaciëlebestanden bestanden doen we een kredietcontrole.",
                                finishedLine: "De kredietcontrole is afgerond.",
                                isFinished: data.case.isCreditRatingAdded == true
                            }
                        );
                    }
                    
                    if(amount > 100000 || data.application.isLoanCollateralPossible){
                        items.push(
                            {
                                title: "Zekerheden",
                                toDoLine: "Ik stel de zekerheden vast en stel dan een overeenkomst hiervoor op.",
                                finishedLine: "De overeenkomst is verwerkt.",
                                isFinished: data.case.isCollateralApproved == true    
                            }
                        );
                    }
                 
                }

                this.setState({
                    c: items.sort(x => !x.isFinished),
                    isLoading: false
                });
            })
    }

    render({ }, { isLoading, c }) {
        if (isLoading)
            return (
                <section class="block relative mb-8 md:mb-10 lg:mb-12">
                    <div class="relative bg-white rounded-lg shadow-lg text-slate-800 p-8">
                        <div class="m-auto w-8 mb-4">
                            <Spinner extraClass="text-brand1-600" size="8" />
                        </div>
                    </div>
                </section>
            )

        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800 p-8">

                    <div class="overflow-hidden bg-white shadow sm:rounded-md">
                        <ul role="list" class="divide-y divide-gray-200">

                            {c.map(x => (
                                <li>
                                    <div class="flex items-center px-4 py-4 sm:px-6">
                                        <div class="flex min-w-0 flex-1 items-center">
                                            <div class="flex-shrink-0">
                                                {x.isFinished ?
                                                    <div class="relative flex h-12 w-12 items-center justify-center rounded-full bg-brand2-500 ">
                                                        <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                                        </svg>
                                                    </div>
                                                    :
                                                    <div class="flex rounded-full h-12 w-12 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" class="align-center w-6 h-6 flex-shrink-0 text-gray-300 self-center">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                }
                                            </div>
                                            <div class="min-w-0 flex-1 px-4">
                                                <div>
                                                    <p class=" lg:text-lg font-bold text-brand2-500">{x.title}</p>
                                                    <p class=" flex items-center text-sm text-slate-400">
                                                        {x.isFinished ? x.finishedLine : x.toDoLine}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        

                    </div>
                </div>
            </section>

        )
    }
}