import { Component } from "preact";
import Spinner from "./spinner";
import { callApi } from "../callApi";
import { Context } from "../context/context";


export default class FreeOfVat extends Component {
    state = {
        isSubmitting: false,
        error: null
    } 

    handleClick = (e, goTo) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        });

        if (typeof (goTo) == "undefined" || goTo == null || goTo.length == 0)
            goTo = this.props.goTo;

        callApi("PUT", "/api/1/application/" + Context.applicationId() + "/free_of_vat", {
                isFreeOfVat: e.target.value == "true"
            }).then(({ status, data }) => {       
                if (status == 400) {
                    this.setState({
                        error: data,
                        isSubmitting: false
                    });
                }
                if (status == 200) {

                    if(e.target.value == "true")
                        sessionStorage.setItem("loan_vat_status","inclusief btw");
                    else
                        sessionStorage.setItem("loan_vat_status","exclusief btw");

                    const evt = new CustomEvent("rs_goTo", {
                        detail: {
                            goTo: goTo,
                            isInForEach: false
                        },
                        bubbles: true,
                        cancelable: true,
                        composed: false,
                    });
    
                    window.dispatchEvent(evt)
                }
            });

     
    }

    render({ options }, {error}){
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <div class="p-4 md:p-6 lg:p-8">
                        <div class="sm:grid sm:grid-cols-3 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                            {options.map(x => (
                                <button type="button" onClick={(e) => this.handleClick(e, x.goTo)} value={x.value} class={(x.isPrimary) ? "my-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand2-500 text-base font-bold text-white hover:bg-brand2-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm lg:text-lg" : "my-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500  lg:text-lg"}>
                                    {this.state.isSubmitting && x.isPrimary ? <Spinner /> : x.label}
                                </button>
                            ))
                            }
 
                            {error == null ? "" :
                                <div class="rounded-md bg-red-50 p-4 mt-4">
                                    <div class="flex">
                                        <div class="flex-shrink-0">
                                            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        <div class="ml-3">
                                            <h3 class="text-sm font-medium text-red-800">Oh nee! Er lijkt iets mis te gaan.</h3>
                                            <div class="mt-2 text-sm text-red-700">
                                                <ul role="list" class="list-disc space-y-1 pl-5">
                                                    <li>{error}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}