import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "./spinner";
import MediaUploader from "../fileUploader";
import DocmentType from "../enums/documentType";


export default class FileUpload extends Component {
    state = {
        isSubmitting: false,
        uploadededFiles: [],
        toUpload: []
    }

    componentDidMount() {
        this.updateData();
    }

    updateData = () => {
        var mode = this.props.mode;
        if(mode == null)
            mode = "application";

        callApi("GET", "/api/1/application/" + Context.applicationId()+"/required_documents/"+mode)
            .then(({ data }) => {
                this.setState({
                    uploadededFiles: data.uploadedDocuments,
                    toUpload: data.requiredDocuments
                });
            });
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.state.toUpload.length > 0) {
            alert("Je hebt nog niet alle benodigde bestanden geupload.");
            return false;
        }

        this.setState({
            isSubmitting: true
        });

        var goTo = this.props.goTo;
       
        const evt = new CustomEvent("rs_goTo", {
            detail: {
                goTo: goTo,
                isInForEach: false
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        });

        window.dispatchEvent(evt) 

    }

    getCompanyName = (companyId) => {
        return this.state.companySigners.filter(x => x.id == companyId)[0].name;
    }

    get = () => {
        var x = this.state.uploadededFiles.reduce((group, product) => {
            const { documentType } = product;
            group[documentType] = group[documentType] ?? [];
            group[documentType].push(product);
            return group;
        }, {});

        var arr = [];
        var keys = Object.keys(x);
        for (var i = 0; i < keys.length; i++) {
            arr.push({ key: keys[i], values: x[keys[i]] });
        }
        return arr;
    }

    deleteFile = (key) =>{
        callApi("DELETE", "/api/1/application/" + Context.applicationId()+"/documents/remove", {
            key: key
        })
            .then(({ data }) => {
                this.updateData();
            });
    }

    fancyTypeName = (type) =>{
     
    var x = type;
    if (type == "Proposal")
        x = "Offerte";
    if (type == "Contract")
        x = "Leeg Contract";
    if (type == "ContractSigned")
        x = "Ondertekend Contract";
    if (type == "ContractSignedProof")
        x = "Bewijsdocument ondertekend Contract ";
    if (type == "ChamberOfCommerceDocument")
        x = "Uittreksel Kamer van Koophandel ";
    if (type == "AmortizationTable")
        x = "Aflossingsoverzicht";
    if (type == "DeMinimisDeclaration")
        x = "Lege De minimis verklaring";
    if (type == "DeMinimisDeclarationSupportProof")
        x = "De minimis bewijsdocument";
    if (type == "DeMinimisDeclarationSigned")
        x = "Ondertekende de minimis verklaring";
    if (type == "InstallationProof")
        x = "Bewijs installatie";
    if (type == "PaymentProof")
        x = "Bewijs betaling";
    if (type == "NoFinancialProblemsStatement")
        x = "Verklaring geen financiële moeilijkheden";
    if(type == "SepaMandate")
        x = "SEPA machtiging";
    if(type == "InstallationInvoice")
        x = "Factuur installatie";
    if(type == "FinacialReportCurrentYear")
        x = "Tussentijdse rapportage";
    if(type == "GeneralAttachment")
        x = "Bijlage";
    if(type == "CollateralAgreement")
        x = "Akte van Zekerheden";
    if(type == "Financials")
        x = "Jaarcijfers";
    if(type == "PermissionOwner")
        x = "Schriftelijke verklaring van dak-/grondeigenaar";
    if(type == "FeasibilityDocument")
        x = "Haalbaarheidsstudie";
    if(type == "SceSdeDecision")
        x = "Subsidiebeschikking (SCE / SDE++)";
    return x;
    }

    render({ }, { toUpload, uploadededFiles }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">

                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">

                    <div class="p-4 md:p-6 lg:p-8">

                        <form onSubmit={this.onSubmit}>

                            <div class="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">

                                {toUpload.length == 0 ? "" :
                                    <div class="mb-6">
                                        <h2 class="text-base font-semibold leading-7 text-brand1-500">Nog te uploaden</h2>

                                        <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                            {toUpload.map((x, i) => (
                                                <div class="pt-6 sm:flex">
                                                    <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                                        <label for={"email" + i} class="pt-1">
                                                            {x.message}
                                                            {x.message == "Project Offerte" ? <>
                                                            <br />
                                                            <p><stong>LET OP:</stong> De offertes die u upload moeten op naam staan van de organisatie die leningnemer wordt.</p>
                                                            </> : ""}
                                                        </label>
                                                    </dt>
                                                    <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <MediaUploader documentType={x.type} oncompleted={this.updateData} />
                                                    </dd>
                                                </div>
                                            ))}


                                        </dl>
                                    </div>
                                }

                                {uploadededFiles.length == 0 ? "" :
                                    <div>
                                        <h2 class="text-base font-semibold leading-7 text-brand1-500 mb-2">Wat je al hebt geupload</h2>
                                        <p>Je hebt de volgende documenten al toegevoegd:</p>

                                        {
                                            this.get().map(x => (
                                                <>
                                                    <h2 class="text-base font-semibold leading-7 text-brand2-500 mt-4">{this.fancyTypeName(x.key)} </h2>

                                                    <dl class="mt-2 space-y-4 divide-y divide-gray-100 border-gray-200 text-sm leading-4 ">

                                                        {x.values.map((x, i) => (
                                                            <div class="pt-2 sm:flex">
                                                                <dt class="font-medium text-gray-900 sm:w-1/2 sm:flex-none sm:pr-6">
                                                                    <label for={"email" + i} class="pt-1">
                                                                        <a href={((sessionStorage.getItem('loan_api_url') ?? "")+"/api/1/application/"+Context.applicationId() + "/file/"+ x.key).replace("//", "/")} target="_blank">
                                                                            {x.name}
                                                                        </a>
                                                                    </label>
                                                                </dt>
                                                                <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                                    <span> </span>
                                                                    <a href="#" onClick={(e) =>{ e.preventDefault(); this.deleteFile(x.key)}}>verwijderen</a>
                                                                </dd>
                                                            </div>
                                                        ))}

                                                        <div class="mt-6">
                                                            <MediaUploader documentType={x.key} oncompleted={this.updateData} label={"Nog een " + this.fancyTypeName(x.key) + " toevoegen"} />
                                                        </div>
                                                    </dl>
                                                </>
                                            ))
                                        }

                                    </div>
                                }

                            </div>


                            {toUpload.length > 0 ? "" :
                                <div class=" py-8 text-right">
                                    <button type="submit" class="inline-flex items-center rounded-full border border-transparent bg-brand2-500 px-6 py-3 text-base font-bold text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                        {this.state.isSubmitting ? <Spinner /> : "Volgende"}
                                    </button>
                                </div>
                            }

                        </form>

                    </div>

                </div>


            </section>
        )
    }
}