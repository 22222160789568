import RadioChoise from "./radioChoice";
import Number from "./number";
import SelectMultiple from "./selectMultiple";
import TextField from "./text";
import AddCompany from "./addCompany";
import AddContactPerson from "./addContactPerson";
import LoanDuration from "./loanDuration";
import ProjectDescription from "./projectDescription";
import ProjectDate from "./projectDate";
import TotalInvestment from "./totalInvestment";
import HowToCalculate from "./howToCalculate";
import SetPercentage from "./setPercentage";
import SetFixedAmount from "./setFixedAmount";
import ListSigners from "./listSigners";
import AlternativeAddress from "./alternativeAddress";
import IsDifferentProjectAddress from "./isDifferentProjectAddress";
import Iban from "./iban";
import AmountOfSubsidyReceived from "./amountOfSubsidyReceived";
import CompanyReceivedSubsidy from "./companyReceivedSubsidy";
import CompanyHasNoFinancialProblems from "./companyHasNoFinancialProblems";
import FileUpload from "./fileUpload";
import OtherPart from "./otherPart";
import Submit from "./submit";
import FreeOfVat from "./freeOfVat";
import SubmitAdvance from "./submitAdvance";
import SubmitPayOut from "./submitPayOut";
import FileUploadExtraDocuments from "./fileUploadExtraFiles";
import IsCollateral from "./isCollateral";
import CreditCheckSubmit from "./creditCheck";
import HasYearlyNumbers from "./hasYearlyNumbers";
import ListSignersFree from "./listSignersFree";

const questions = {
    "Number": Number,
    "RadioChoise": RadioChoise,
    "SelectMultiple" : SelectMultiple, 
    "TextField" : TextField,
    "AddCompany" : AddCompany,
    "AddContactPerson" : AddContactPerson, 
    "LoanDuration": LoanDuration,
    "ProjectDescription" : ProjectDescription, 
    "ProjectDate": ProjectDate,
    "TotalInvestment": TotalInvestment,
    "HowToCalculate": HowToCalculate,
    "SetPercentage": SetPercentage,
    "SetFixedAmount": SetFixedAmount,
    "ListSigners" : ListSigners,
    "AlternativeAddress": AlternativeAddress,
    "IsDifferentProjectAddress": IsDifferentProjectAddress,
    "Iban": Iban,
    "AmountOfSubsidyReceived": AmountOfSubsidyReceived,
    "CompanyReceivedSubsidy": CompanyReceivedSubsidy,
    "CompanyHasNoFinancialProblems": CompanyHasNoFinancialProblems,
    "FileUpload": FileUpload,
    "OtherPart": OtherPart,
    "Submit" : Submit,
    "FreeOfVat" : FreeOfVat,
    "SubmitAdvance": SubmitAdvance,
    "SubmitPayOut": SubmitPayOut,
    "FileUploadExtraDocuments": FileUploadExtraDocuments,
    "IsCollateral": IsCollateral,
    "CreditRisk": CreditCheckSubmit,
    "HasYearlyNumbers": HasYearlyNumbers,
    "ListSignersFree": ListSignersFree
};

function Question(props) {
    const Q = questions[props.type];  
    return <Q {...props} />;
}

export default Question;