import { Component } from "preact";
import Spinner from "./spinner";


export default class RadioChoise extends Component {
    state = {
        isSubmitting: false     
    }

    handleClick = (e, goTo) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        });

        if (typeof (goTo) == "undefined" || goTo == null || goTo.length == 0)
            goTo = this.props.goTo;

        const evt = new CustomEvent("rs_goTo", {
            detail: {
                goTo: goTo,
                isInForEach: false
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        });

        window.dispatchEvent(evt);
    }

    render({ options }){
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <div class="p-4 md:p-6 lg:p-8">
                        <div class="sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                            {options.map(x => (
                                <button type="button" onClick={(e) => this.handleClick(e, x.goTo)} class={(x.isPrimary) ? "my-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand2-500 text-base font-bold text-white hover:bg-brand2-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm lg:text-lg" : "my-2 w-full inline-flex justify-center rounded-md border border-brand1-500 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500       lg:text-lg"}>
                                    {this.state.isSubmitting && x.isPrimary ? <Spinner /> : x.label}
                                </button>
                            ))
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}