import { Component } from "preact";
import { route } from "preact-router";
import { callApi } from "../callApi";
import { Context } from "../context/context";

export default class Router extends Component {
    componentWillMount(){
        var operator = this.props.operator;
        var compareWith = this.props.compareWith;

        if(this.props.type == "Redirect")
            route(this.props.goTo, true);            
       
        if(this.props.type == "AllowedRequests"){    
            var mode = this.props.mode;        
            callApi("GET", "/api/1/application/"+Context.applicationId()+"/"+Context.getCId()+"/allowed_requests").then(({data}) => {              
                var compareValue = data.isPayOutRequestAllowed; 
                if(mode == "Advance")
                    compareValue = data.isAdvanceAllowed;

                this.handleCompare(operator, compareValue, compareWith);
            });
        }  
        
        if(this.props.type == "CheckIfCollateralAllowedForCollective"){
            callApi("GET", "/api/1/application/"+Context.applicationId())
            .then(({data}) => {              
                var compareValue = data.amountToLoan; 
                alert(compareValue);
                alert(compareWith);
                alert(operator);
                this.handleCompare(operator, compareValue, compareWith);                
            });
        }
    }

    handleCompare = (operator, compareValue, compareWith) => {      
        if (operator == ">") {
            if (compareValue > compareWith)
                this.handleTrue();
            else
                this.handleFalse();
        }
        
        if(operator == "CONTAINS"){           
            if (compareValue.split(",").indexOf(compareWith) != -1)
                this.handleTrue();
            else
                this.handleFalse();
        }

        if(operator == "NOT CONTAINS"){           
            if (compareValue.split(",").indexOf(compareWith) == -1)
                this.handleTrue();
            else
                this.handleFalse();
        }

        if(operator == "COUNT IS MORE THAN"){           
            if (compareValue.split(",").length > compareWith)
                this.handleTrue();
            else
                this.handleFalse();
        }

        if(operator == "EXISTS"){
            if (typeof(compareValue) !== "undefined" && compareValue != null )
                this.handleTrue();

            else
                this.handleFalse();
        }

        if (operator == "==") {         
            if (compareValue == compareWith)
                this.handleTrue();

            else
                this.handleFalse();
        }

        if (operator == "!=") {
            if (compareValue != compareWith)
                this.handleTrue();

            else
                this.handleFalse();
        }

        if (operator == "<") {
            if (compareValue < compareWith)
                this.handleTrue();

            else
                this.handleFalse();
        }

        if (operator == "<=") {
            if (compareValue <= compareWith)
                this.handleTrue();

            else
                this.handleFalse();
        }

        if (operator == ">=") {
            if (compareValue >= compareWith)
                this.handleTrue();

            else
                this.handleFalse();
        }
    }
    
    handleTrue = () =>{
        var goTo = this.props.whenTrueGoTo;
        this.dispatchGoToEvent(goTo);
    }

    handleFalse = () =>{
        var goTo = this.props.whenFalseGoTo;
        this.dispatchGoToEvent(goTo);
    }

    dispatchGoToEvent = (value) =>{
        const e = new CustomEvent("rs_goTo", {
            detail: {
                goTo: value
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        });

        window.dispatchEvent(e)
    }
}