import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";

export default class Video extends Component {
    state = {
        showText: false,
        canShowNextButton: false,
        reachedPausedMoment: false,
        showedPausedMoment: false
    }

    componentDidMount() {
        this.videoElement.addEventListener("timeupdate", this.handleProgress);
        var scope = this;
        window.addEventListener("rs_scaleQuestion_change", () => {
            scope.setState({
                showedPausedMoment: true
            }, () => {
                scope.videoElement?.play();
            });
        });

        if (this.props.file == "assets/videos/bedankt.mp4")
            callApi("PUT", "/api/SetProgress", {
                "ParticipatorId": Context.applicationId(),
                "MeasurementId": Context.measurementId(),
                "Progress": 100
            });
    }

    toggleDisplayMode = (e) => {
        e.preventDefault();
        this.setState({
            showText: !this.state.showText,
            canShowNextButton: true
        });
    }

    insertDynamicData = (string) => {
        return string.replace("{firstname}", Context.firstname());
    }

    ended = () => {
        this.setState({
            canShowNextButton: true
        });
    }

    goNext = (e) => {
        e.preventDefault();
        const evt = new CustomEvent("rs_goTo", {
            detail: {
                goTo: this.props.goTo
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        });

        window.dispatchEvent(evt)
    }

    handleProgress = (e) => {
        if (typeof (this.props.pauseAt) == "undefined")
            return;

        var duration = e.target.currentTime;
        if (isNaN(duration))
            return;

        if (duration > this.props.pauseAt && !this.state.showedPausedMoment) {
            e.target.pause();
            this.setState({
                showedPausedMoment: true,
                reachedPausedMoment: true
            });
        }
    }

    render({ header, text, file }, { showText, canShowNextButton, reachedPausedMoment }) {
        return (
            <div class="mb-8">
                <div class="border-b border-gray-200">
                    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                        <a href="#" onclick={this.toggleDisplayMode} class={(!showText ? "border-brand2-500 text-brand2-500 bold" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300") + " whitespace-nowrap py-4 px-1 border-b-2 font-medium"}>Videoversie</a>
                        <a href="#" onclick={this.toggleDisplayMode} class={(showText ? "border-brand2-500 text-brand2-500 bold" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300") + " whitespace-nowrap py-4 px-1 border-b-2 font-medium"}>Tekstversie</a>
                    </nav>
                </div>
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800 ">
                    <div class="p-4 md:p-6 lg:p-8">
                        <h2 class="truncate text-2xl font-bold text-gray-900 pb-8">{this.insertDynamicData(header)}</h2>
                        {showText ?
                            <div class="prose ">
                                {text.map((x) => (
                                    <p class="pb-4 text-gray-900" dangerouslySetInnerHTML={{ __html: x }}></p>
                                ))}                             
                            </div> :
                            <div>
                                <video controls autoplay class="w-full" onEnded={this.ended} ref={el => this.videoElement = el}>
                                    <source src={file} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        }

                    
                        <div class="py-8 text-right">
                            {!canShowNextButton ? "" :
                                <a onclick={this.goNext} href="#" class="inline-flex items-center rounded-full border border-transparent bg-brand2-500 px-6 py-3 text-base font-bold text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Volgende</a>
                            }
                        </div>
                    </div>
                </div>
            </div>)
            ;
    }
} 