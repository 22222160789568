export class Context {
   
  
    static applicationId() {
        return sessionStorage.getItem("loan_applicationId");
    }

    static getAId(){
        return sessionStorage.getItem("loan_aid");
    }
   
    static getCId(){
        return sessionStorage.getItem("loan_cid");
    }
   
} 