
import { Component } from "preact";
import Spinner from "./spinner";
import { Context } from "../context/context";
import { callApi } from "../callApi";


export default class AddCompany extends Component {
    state = {
        isSubmitting: false,
        error: null
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.isSubmitting)
            return;
 
        this.setState({ 
            isSubmitting: true
        });

        var goTo = this.props.goTo;
        var goToWhenNotAllowed = this.props.goToWhenNotAllowed ?? "";
        var allowedLegalStructures = this.props.allowedLegalStructures ?? [];

        callApi("POST", "/api/1/application/"+Context.applicationId()+"/company/",{ 
            registrationNumber: e.target.elements["kvk"].value,
            role: 0
        }).then(({status, data}) => {
            if(status == 400){
               this.setState({
                    error: true,
                    isSubmitting: false
               });
            }
            if(status == 200){              
                if(allowedLegalStructures.length > 0){
                    var legalStructure = data.legalStructure;              
                    if(allowedLegalStructures.indexOf(legalStructure) == -1){
                        const e = new CustomEvent("rs_goTo", {
                            detail: {
                                goTo: goToWhenNotAllowed,
                                isInForEach: false
                            },
                            bubbles: true,
                            cancelable: true,
                            composed: false, 
                        });
                       
                        window.dispatchEvent(e)
                        return;
                    }
                }

                if(data.legalStructure == "FaithGroup")
                    goTo = "Company02_FaithGroup";
                
                sessionStorage.setItem("loan_company", data.name);
                sessionStorage.setItem("loan_company_address", data.address.street + " " +data.address.houseNumber+(data.address.houseNumberAddition ?? "")+ ", " +data.address.city);

                const e = new CustomEvent("rs_goTo", {
                    detail: {
                        goTo: goTo,
                        isInForEach: false
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false, 
                });
               
                window.dispatchEvent(e)
            }
        });
    }

    render({}, {error}) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <form onSubmit={this.handleSubmit}>
                        <div class="p-4 md:p-6 lg:p-8">
                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                                <div class="mb-6"> 
                                    <label for="kvk" class="block mb-2 text-sm font-bold text-brand2-500">KvK-nummer</label>
                                    <input type="string" required maxLength="8" minLength="8" id="kvk" class="sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-brand2-500 block w-full p-2.5 "/>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                <button type="submit" class="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand2-500 text-base font-bold text-white hover:bg-brand2-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand2-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                    {this.state.isSubmitting ? <Spinner /> : "Verder"}
                                </button>
                            </div>
                                    
                            {error == null ? "" : 
                                <div class="rounded-md bg-red-50 p-4 mt-4">
                                    <div class="flex">
                                    <div class="flex-shrink-0">
                                        <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <div class="ml-3">
                                        <h3 class="text-sm font-medium text-red-800">Oh nee! Er lijkt iets mis te gaan.</h3>
                                        <div class="mt-2 text-sm text-red-700">
                                            <ul role="list" class="list-disc space-y-1 pl-5">
                                                <li>We kunnen het opgegeven KvK-nummer niet vinden</li>                               
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            }
                        </div>
                    </form>

                </div>
       
            </section>
        )
    }
}