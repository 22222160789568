
import { Component } from "preact";
import { callApi } from "../callApi";
import { Context } from "../context/context";
import Spinner from "./spinner";


export default class SelectMultiple extends Component {
    state = {
        persons: [],
        isSubmitting: false
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        var form = e.target;
        var goto = this.props.goTo;

        if (this.state.isSubmitting)
            return;
     
        var answerInputs = form.querySelectorAll("input");      
        if(answerInputs.length == 0){
            alert("selecteer ten minste 1");
            return;
        }

        this.setState({
            isSubmitting: true
        });

        var answers = [];
        for (var i = 0; i < answerInputs.length; i++) {
            answers.push({
                QuestionCode: answerInputs[i].name,
                Value: answerInputs[i].checked+"",
            });
        }

        callApi("POST", "/api/RecordAnswers", {
            "MeasurementId": Context.measurementId(),
            "ParticipatorId": Context.applicationId(),
            "Answers": answers
        }).then(() => {
            const e = new CustomEvent("rs_goTo", {
                detail: {
                    goTo: goto, 
                    isInForEach: this.props.isInForEach ?? false
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(e)
        });
    }

    handleChange = (e) =>{
        var t = e.target;
        if(t.checked && t.name == "CXloc13"){
            var fields = this.options.querySelectorAll("input:checked");
            for(var i =0; i < fields.length; i++){
                var field = fields[i];
                if(field.name == "CXloc13")
                    continue;
                
                field.checked = false;
            }
        } else{
            var field = this.options.querySelector("input[name=CXloc13]");
            if(field != null)
                field.checked = false;
        }
    }

    formatLabel = (text) =>{
        return text;
    }

    render({ questions = [], displayOnly = false }, { isSubmitting }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <div class="p-4 md:p-6 lg:p-8">
                        <form onSubmit={this.handleSubmit}>
                           <div ref={el => this.options = el} class="flex flex-wrap">
                                {questions.map((x) => (
                                    <div class="w-full pb-2">
                                        <label>
                                            <input type="checkbox" name={x.code} value={x.value}  class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 mr-3" onChange={this.handleChange} /> 
                                            <span dangerouslySetInnerHTML={{ __html: this.formatLabel(x.label) }}></span>
                                        </label>
                                    </div> 
                                ))}                             
                            </div>

                            {displayOnly ? "" :
                                <div class="py-8 text-right">
                                    <button type="submit" class="inline-flex items-center rounded-full border border-transparent bg-brand2-500 px-6 py-3 text-base font-bold text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                        {isSubmitting ? <Spinner /> : "Volgende"}
                                    </button>
                                </div>}
                        </form>
                    </div>
                </div>
            </section>
        )
    }
}