export async function callApi(method, url, body) {	
	const headers = new Headers();

	const options = {
		method: method,
		headers: headers		
	};

	if (typeof (body) !== "undefined"){
		headers.append("Content-Type", "application/json");
		options.body = JSON.stringify(body);
	}

	var fullUrl = ((sessionStorage.getItem('loan_api_url') ?? "") + url).replace("//", "/");

	return fetch(fullUrl, options)
			.then((response) => {
				if(response.status == 200)
					return new Promise((resolve) => response.json()
						.then((json) => resolve({
							data: json,
							status: response.status,
							ok: response.ok,						
						})));					
				else
				return new Promise((resolve) => response.text()
					.then((txt) => resolve({
						data: txt,
						status: response.status,
						ok: response.ok,						
					})));						
			});
}

export async function loadStatic(method, url, body) {
	const headers = new Headers();

	if (typeof (body) !== "undefined" && body.length > 2)
		headers.append("Content-Type", "application/json");

	const options = {
		method: method,
		headers: headers,
		body: body
	};

	return fetch(url, options)
		.then(data => {
			if (data != null && typeof (data) !== "undefined")
				return data.json();
			return {};
		})
		.catch(error => console.log(error));
}
