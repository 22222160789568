import CaseStatus from "./caseStatus";

const displays = {  
    "CaseStatus": CaseStatus
};

function Display(props) {    
    const D = displays[props.type];  
    return <D {...props} />;
}  
  
export default Display;