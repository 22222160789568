import { Component } from 'preact';
import { callApi } from '../../components/callApi';
import Spinner from '../../components/questions/spinner';
import ChatConversation from '../../components/page/conversation';

export default class CreditCheck extends Component {
    state = {
        isLoaded: false,
        isValid: false,
        isCompleted: false   
    }


	componentDidMount() {
        callApi("GET", "/api/1/application/" + this.props.applicationId + "/" + this.props.caseId)
            .then(({ data, status }) => {
                this.setState({
                    isLoaded: true,
                    isValid: status == 200 && this.props.token == data.case.creditRatingToken,                  
                    isCompleted: data.case.creditRatingAddedOn != null                  
                });

                if (status == 200 && this.props.token == data.case.creditRatingToken) {
                    sessionStorage.setItem("loan_applicationId", this.props.applicationId);
                    sessionStorage.setItem("loan_aid", this.props.applicationId);
                    sessionStorage.setItem("loan_cid", this.props.caseId);
                    sessionStorage.setItem("loan_caseId", this.props.caseId);
                    sessionStorage.setItem("loan_creditToken", data.case.creditRatingToken);
                    sessionStorage.setItem("loan_caseName", data.case.name);
                } else {
                    sessionStorage.removeItem("loan_applicationId", this.props.applicationId)
                    sessionStorage.removeItem("loan_aid");
                    sessionStorage.removeItem("loan_cid");
                    sessionStorage.removeItem("loan_caseId");
                    sessionStorage.removeItem("loan_creditToken");
                    sessionStorage.removeItem("loan_caseName");
                }
            });

	}

	render({ }, {isLoaded, isValid, isCompleted }) {
        if (!isLoaded)
        return (
            <div class="text-center mx-auto">
                <div class="mx-auto h-6 w-6 mb-4 ">
                    <Spinner extraClass="text-brand1-600" />
                </div>
                <p class="text-sm">We pakken de zaakgegevens erbij...</p>
            </div>
        );

    if (!isValid)
        return (
            <div class="text-center mx-auto">
                <p class="text-sm">Deze link is (niet) meer actief</p>
            </div>
        );

   if (isCompleted)
        return (
            <div class="text-center mx-auto">
                <p class="text-sm">Bedankt! Er is een score ingediend voor deze link. Er is geen verdere actie nodig.</p>
            </div>
        );

        return (
            <>                            
                <ChatConversation list="CreditCheck" showBarBottom={true} />
            </>
        );

	}
}