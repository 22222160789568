const DeoSkin = (props) => {
    return (
        <div id="wrapper">

			<header id="header">
				<div class="row">
					<div class="logo">
						<a href="/"><img src="https://www.drentseenergieorganisatie.nl//Content/images/logo.jpg" alt="Energiefonds Drenthe" width="250"
								height="160" /></a>
					</div>
				
				</div>
			</header>

			<main id="main" role="main" class="inset-shadow bg-slate-100">
				<div class="row">
					<div class="small-12 columns">
						<div class="intro-block tailor-made alt-page">
							<div class="row  form-page">
                                {props.children}
							</div>
						</div>
					</div>
				</div>
			</main>

			<footer id="footer" style="overflow: hidden; margin-bottom: 60px;">
				<div class="footer-block">
					<span class="skew-bg-left"></span>
					<span class="skew-bg-right"></span>
					
				</div>
				
			</footer>
		</div>
    );
}

export default DeoSkin; 