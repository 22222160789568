
import { Component } from "preact";
import Spinner from "./spinner";
import { callApi } from "../callApi";
import { Context } from "../context/context";


export default class ProjectDescription extends Component {
    state = {
        isSubmitting: false
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.isSubmitting)
            return;
 
        this.setState({ 
            isSubmitting: true
        });

        var goTo = this.props.goTo;

        callApi("PUT", "/api/1/application/"+Context.applicationId()+"/energyfunddrenthe/project_description", {description:  e.target.elements["Description"].value})
            .then(({data, status}) => {
                if(status == 400){
                    this.setState({
                     error: true,
                     isSubmitting: false
                    });
                 }
                 if(status == 200){                 
                    const e = new CustomEvent("rs_goTo", {
                        detail: {
                            goTo: goTo,
                            isInForEach: false
                        },
                        bubbles: true,
                        cancelable: true,
                        composed: false, 
                    });
                   
                    window.dispatchEvent(e)
                }
            })
    }

    render() {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <form onSubmit={this.handleSubmit}>
                        <div class="p-4 md:p-6 lg:p-8">
                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                                <div class="mb-6">                   
                                    <textarea required name="Description" id="Description" class="h-32 sm:text-sm lg:text-base border border-gray-300 text-stone-800 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-brand2-500 block w-full p-2.5 " />
                                </div>
                            </div>
                            <div class=" py-8 text-right">
                                <button type="submit" class="inline-flex items-center rounded-full border border-transparent bg-brand2-500 px-6 py-3 text-base font-bold text-white shadow-sm hover:bg-brand1-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                    {this.state.isSubmitting ? <Spinner /> : "Verder"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>        
            </section>
        )
    }
}