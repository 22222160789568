import { Component } from "preact";

export default class ChatBubble extends Component {
    replaceDynamicText = (text) => {     
        if(text.indexOf("{loan_company}") > 0)
            text = text.replace("{loan_company}", sessionStorage.getItem("loan_company"))
        if(text.indexOf("{loan_company_address}") > 0)
            text = text.replace("{loan_company_address}", sessionStorage.getItem("loan_company_address"))
        if(text.indexOf("{vat_status}") > 0)
            text = text.replace("{vat_status}", sessionStorage.getItem("loan_vat_status"))
        if(text.indexOf("{loan_caseName}") > 0)
            text = text.replace("{loan_caseName}", sessionStorage.getItem("loan_caseName"))
        
        if(text.indexOf("{request_advance_rules}") > 0){
            var rules = "<ul class=\"list-disc pl-4\"><li>Een betaalbewijs van tenminste 30% van de investering</li><li>Facturen van tenminste 50% van de investering</li></ul>";

            var loanName = sessionStorage.getItem("loan_loanName");
            if(loanName == "Energielening collectieven")
                text = "We hebben een aantal documenten van je nodig. Deze staan hieronder. Je kan per type bestand meerdere bestanden uploaden";
            if(loanName == "Maatschappelijke Energielening")
                rules = "<ul class=\"list-disc pl-4\"><li>Facturen van tenminste 50% van de investering</li></ul>";

            text = text.replace("{request_advance_rules}", rules)
        }

        return text;
    }

    render({ text }) {
        return (
            <section class="animate-appear relative pl-16 lg:pl-20 mb-8 md:mb-10 lg:mb-12 ">
                <img class="absolute bottom-0 left-0 block h-12 w-12  lg:h-16 lg:w-16 border border-slate-400 rounded-full"
                    src="/assets/images/cora.jpg?v=1"
                    alt="avatar" />

                <div class=" relative p-4 md:p-6 lg:p-8 bg-white rounded-xl shadow-lg">
                    <p class="text-slate-800  lg:text-lg" dangerouslySetInnerHTML={{ __html: this.replaceDynamicText(text) }} />
                    <div class="absolute bottom-4 lg:bottom-6 -left-2 w-4 h-4 bg-white rotate-45 shadow-lg"></div>
                </div>
            </section>
        );
    }
}