import { Component } from "preact";
import ChatBubble from "./bubble";
import ChatTyping from "./typing";

export default class ChatMessage extends Component {
    state = {
        isNew: true
    };
   
    componentDidMount() {       
        var target = this.props.text.length * 7.75;
        setTimeout(() => {
            this.setState({isNew: false}, () => {                        
                this.props.afterRender(); 
            });
        }, target);          
    }

     render({text }, {isNew}){
        if(isNew)
            return(
                <ChatTyping  />
            )
        else
            return(
                <ChatBubble text={text}  />
            )
     }
}