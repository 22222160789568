
import { Component } from "preact";
import Spinner from "./spinner";
import { Context } from "../context/context";
import { callApi } from "../callApi";


export default class AddContactPerson extends Component {
    state = {
        isSubmitting: false,
        error: null
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.isSubmitting)
            return;

        this.setState({
            isSubmitting: true
        });

        var goTo = this.props.goTo;
        const myFormData = new FormData(e.target);
        const formDataObj = Object.fromEntries(myFormData.entries());

        callApi("POST", "/api/1/application/" + Context.applicationId() + "/contact_person/", formDataObj).then(({ status, data }) => {
            if (status == 400) {
                this.setState({
                    error: true,
                    isSubmitting: false
                });
            }
            if (status == 200) {
                const e = new CustomEvent("rs_goTo", {
                    detail: {
                        goTo: goTo,
                        isInForEach: false
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                });

                window.dispatchEvent(e)
            }
        });
    }

    render({ }, { error }) {
        return (
            <section class="block relative mb-8 md:mb-10 lg:mb-12">
                <div class="relative bg-white rounded-lg shadow-lg text-slate-800">
                    <form onSubmit={this.handleSubmit}>
                        <div class="p-4 md:p-6 lg:p-8">
                            <div class="relative mb-4 md:mb-6 lg:mb-8">
                                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                    <div class="sm:col-span-2 sm:col-start-1">
                                        <label for="firstName" class="block text-sm font-medium leading-6 text-gray-900">Voornaam *</label>
                                        <div class="mt-2">
                                            <input type="text" name="firstName" id="firstName" required autocomplete="firstName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand1-600 sm:text-sm sm:leading-6" />
                                        </div>
                                    </div>
                                    <div class="sm:col-span-2">
                                        <label for="middleName" class="block text-sm font-medium leading-6 text-gray-900">Tussenvoegsel(s)</label>
                                        <div class="mt-2">
                                            <input type="text" name="middleName" id="middleName" autocomplete="middleName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand1-600 sm:text-sm sm:leading-6" />
                                        </div>
                                    </div>
                                    <div class="sm:col-span-2">
                                        <label for="lastName" class="block text-sm font-medium leading-6 text-gray-900">Achternaam *</label>
                                        <div class="mt-2">
                                            <input type="text" name="lastName" required id="lastName" autocomplete="lastName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand1-600 sm:text-sm sm:leading-6" />
                                        </div>
                                    </div>
                                    <div class="sm:col-span-3">
                                        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">E-mailadres *</label>
                                        <div class="mt-2">
                                            <input id="email" name="email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand1-600 sm:text-sm sm:leading-6" />
                                        </div>
                                    </div>
                                    <div class="sm:col-span-3">
                                        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">(Mobiel) Telefoonnummer *</label>
                                        <div class="mt-2">
                                            <input id="phone" name="phone" type="text" required autocomplete="phone" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand1-600 sm:text-sm sm:leading-6" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 lg:gap-6 sm:grid-flow-row-dense">
                                <button type="submit" class="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand2-500 text-base font-bold text-white hover:bg-brand2-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand2-500 sm:col-start-2 sm:text-sm lg:text-lg">
                                    {this.state.isSubmitting ? <Spinner /> : "Verder"}
                                </button>
                            </div>

                            {error == null ? "" :
                                <div class="rounded-md bg-red-50 p-4 mt-4">
                                    <div class="flex">
                                        <div class="flex-shrink-0">
                                            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        <div class="ml-3">
                                            <h3 class="text-sm font-medium text-red-800">Oh nee! Er lijkt iets mis te gaan.</h3>
                                            <div class="mt-2 text-sm text-red-700">
                                                <ul role="list" class="list-disc space-y-1 pl-5">
                                                    <li>Telefoonnummer lijkt niet te kloppen</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </section>
        )
    }
}